$(".dropdown .dropdown--link").on("click", (event) => {
    const element = $(event.target);

    if (!element.is("a")) {
        event.preventDefault();

        if ($(event.target).parent().hasClass("dropdown")) {
            $(event.target).parent(".dropdown").toggleClass("dropdown--expanded");
        } else {
            console.log("to: ", $(element).find("a").attr("href"));
            window.location.href = $(element).find("a").attr("href");
        }
    }
});

$(".m-navbar__mainItems > .m-navbar__mainItems--toggleIcon").on("click", (event) => {
    $(".m-navbar").find(".e-list").first().toggleClass("visible");
});

window.onclick = (event) => {
    if ($(event.target).parents(".m-navbar").length === 0) {
        $(".visible").removeClass("visible");
        $(".dropdown--expanded").removeClass("dropdown--expanded");
    }
};